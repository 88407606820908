<template>
    <div>
        <!-- <div v-if="this.$route.name == 'userEdit'">
            <div class="avatar"> -->
                <!-- <crop-upload v-if="userInfo.avatar" style="margin-left: 54px" v-model="userInfo.avatar"></crop-upload> -->
                <!-- <crop-upload style="margin-left: 54px" v-model="sizeForm.avatar"></crop-upload> -->
                <!-- <div style="position: relative; z-index: 99; margin-left: -2px; margin-top: 10px">
                    <el-button @click="uploadFileFn">修改头像</el-button>
                </div> -->
                <!-- <img id="uploadFile" @change="upload" type="file" accept="jpg,png,gif,jpeg" v-show="false" /> -->
            <!-- </div>
        </div> -->
        <div class="top">
            <el-form ref="form" :model="sizeForm" :rules="registerRule">
                <el-form-item label="机构名称" prop="name">
                    <el-input style="width: 300px" placeholder="请输入单位名称" v-model="sizeForm.name" :disabled="whether"></el-input>
                </el-form-item>
                <el-form-item label="机构性质" prop="nature">
                    <el-radio-group v-model="sizeForm.nature">
                        <el-radio v-for="item in natureOptions" :key="item.value" :label="item.value" :disabled="whether">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="establishedAt" label="成立日期">
                    <el-date-picker
                        v-model="sizeForm.establishedAt"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                        :disabled="whether"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="官方地址" prop="url">
                    <el-input style="width: 300px" v-model="sizeForm.url" placeholder="请输入官方地址" :disabled="whether"></el-input>
                </el-form-item>
                <el-form-item class="block" prop="introduction" label="机构简介">
                    <el-input
                        class="textarea"
                        type="textarea"
                        v-model="sizeForm.introduction"
                        maxlength="200"
                        show-word-limit
                        :autosize="{ minRows: 6, maxRow: 5 }"
                        placeholder="请填写对接描述，不超过200字"
                        resize="none"
                        :disabled="whether"
                    ></el-input>
                </el-form-item>
                <el-form-item label="机构logo" prop="logo">
                    <single-upload class="upload" :disabled="whether" v-model="sizeForm.logo"></single-upload>
                </el-form-item>
                <div class="description">请上传机构logo图片，格式JPG或PNG或JPEG，大小不超过1M</div>
                <el-form-item label="营业执照" prop="businessLicense">
                    <single-upload class="upload" :disabled="whether" v-model="sizeForm.businessLicense"></single-upload>
                </el-form-item>
                <div class="description">请上传清晰图片，格式JPG或PNG或JPEG，大小不超过1M</div>
                <el-form-item label="统一社会信用代码" prop="uscc">
                    <el-input
                        style="width: 300px"
                        placeholder="请输入18位统一社会信用代码"
                        v-model="sizeForm.uscc"
                        :disabled="whether"
                    ></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="nickname">
                    <el-input
                        style="width: 300px"
                        placeholder="请输入您的真实姓名"
                        v-model="sizeForm.nickname"
                        :disabled="whether"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input
                        :disabled="true"
                        style="width: 300px"
                        placeholder="请输入您的联系方式"
                        v-model="sizeForm.phone"
                    ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input style="width: 300px" placeholder="请输入" v-model="sizeForm.email" :disabled="whether"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn">
                <el-button type="primary" @click="agencyReturn"  v-if="whether">返回</el-button>
                <el-button type="primary" @click="onSubmit" v-else>提交申请</el-button>
                <!-- <el-button type="info" @click="$router.go(-1)">返回</el-button> -->
            </div>
        </div>
    </div>
</template>
<script>
// import { createIcon } from '@download/blockies';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            sizeForm: {
                name: '',
                uscc: '',
                url: '',
                nature: '',
                establishedAt: '',
                introduction: '',
                logo: '',
                businessLicense: '',
                nickname: '',
                email: '',
                // avatar: 'https://zhirongip.oss-cn-hangzhou.aliyuncs.com/image/2021-06-30-17-02-42uzAOUPcw.jpeg',
                phone: ''
            },
            registerRule: {
                name: { required: true, message: '请输入单位名称', trigger: 'blur' },
                nature: { required: true, message: '请输入机构性质', trigger: 'blur' },
                phone: { required: true, message: '请输入您的联系方式', trigger: 'blur' },
                email: { type: 'email', required: true, message: '请输入您的邮箱', trigger: 'blur' },
                introduction: { required: true, message: '请填写对接描述', trigger: 'blur' },
                businessLicense: { required: true, message: '请添加营业执照', trigger: 'blur' },
                logo: { required: true, message: '请添加企业logo', trigger: 'blur' },
                uscc: { required: true, min: 18, max: 18, message: '请输入18位统一社会信用代码', trigger: 'blur' },
                nickname: { required: true, message: '请输入您的真实姓名', trigger: 'blur' }
            },
            natureOptions: [
                { label: '军工单位', value: 'MILITARY_INDUSTRY' },
                { label: '民口企业', value: 'MINKOU' },
                { label: '民营企业', value: 'PRIVATE' },
                { label: '其他', value: 'OTHER' }
            ],
            whether: false
        };
    },
    computed: {
        ...mapState(['userInfo', 'institutionInfo']),
        type() {
            return this.userInfo.authorities.find(item => {
                return item.name == 'ROLE_INSTITUTION' || item.name == 'ROLE_PERSONAL';
            });
        }
    },
    created() {
        console.log(this.type);
        this.$http
            .get('/institution/my')
            .then(res => {
                // console.log(res);
                if(res.status === "PASS"){
                    this.whether = true
                }
                this.sizeForm = {
                    ...res,
                    nickname: this.userInfo.nickname,
                    phone: this.userInfo.phone,
                    avatar: this.userInfo.avatar,
                    email: this.userInfo.email
                };
            })
            .catch(e => {
                console.log(e);
            });
        (this.sizeForm.nickname = this.userInfo.nickname), (this.sizeForm.phone = this.userInfo.phone);
    },
    methods: {
        agencyReturn(){
            this.$emit('change-type', 1)
        },
        uploadFileFn() {
            return document.getElementById('uploadFile').click();
        },
        upload(e) {
            this.sizeForm.avatar = window.URL.createObjectURL(e.target.files[0]);
        },
        onSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$confirm('实名认证审核一旦通过将无法修改，请您谨慎填写', '提示', { type: 'warning' }).then(() => {
                        this.preservation();
                    });
                }
            });
        },
        preservation() {
            let data = { ...this.sizeForm };
            delete data.nickname;
            delete data.phone;
            delete data.email;
            data.userId = this.userInfo.id;
            this.saving = true;
            let userInfo = {
                ...this.userInfo,
                nickname: this.sizeForm.nickname,
                phone: this.sizeForm.phone,
                // avatar: this.userInfo.avatar,
                email: this.sizeForm.email
            };
            userInfo.authorities = [
                ...userInfo.authorities,
                {
                    name: 'ROLE_INSTITUTION'
                }
            ];
            this.$http
                .post('user/save', userInfo, {
                    body: 'json'
                })
                .then(res => {
                    this.sizeForm.avatar = res.avatar;
                    this.$store.dispatch('getUserInfo');
                    return this.$http.post('/institution/save', data, { body: 'json' }).then(res => {
                        this.sizeForm = res;
                        this.saving = false;
                        this.$store.dispatch('getUserInfo');
                        this.$router.push('/Authentications');
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-form {
    margin-top: 30px;
    width: 560px;
}
/deep/ .el-form-item__label {
    width: 135px;
    white-space: nowrap;
    color: #313233;
    text-overflow: ellipsis;
    line-height: 40px;
    font-size: 13px;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
// /deep/ .el-radio__inner {
//     margin-left: 10px;
// }
/deep/ .el-form-item__error {
    margin-left: 135px;
}
/deep/ .el-button {
    width: 110px;
    border-radius: 4px;
    margin-left: 25px;
}
.btn {
    margin: 60px 0 0 108px;
}
.upload {
    .flex();
}
.avatar {
    margin: 30px 0 -220px 610px;
}
.top {
    margin: 0 auto;
    img {
        width: 120px;
        height: 120px;
        padding-left: 68px;
    }
    h3 {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 28px;
        margin: 24px 0 8px;
        padding-left: 93px;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #939599;
        line-height: 24px;
    }
    .textarea {
        width: 400px;
        height: 102px;
        overflow: hidden;
        padding-bottom: 40px;
    }
}
.description {
    font-size: 14px;
    font-weight: 400;
    color: #c8c9cc;
    line-height: 24px;
    margin: -11px 0 20px 135px;
}
/deep/ .el-button{
    width: 220px;
    height: 36px;
    background: #01a041;
    border-radius: 4px;
}
/deep/ .el-button--primary{
    border-color: #01a041;
}
</style>
