<template>
    <div class="main">
        <div class="main_con" v-if="personalData === 1">
            <div class="title">个人资料</div>
            <div class="top">
                <single-upload class="img" v-model="sizeForm.avatar" :modifyAvatar = true>
                </single-upload>
                <!-- <input id="uploadFile" @change="upload" type="file" accept="jpg,png,gif,jpeg" v-show="false" /> -->
                <el-form :model="form" ref="form" label-width="0">
                    <el-form-item style="margin-left: -60px; margin-top: 80px; width: 400px" class="form_con">
                        <span class="el-icon-user"></span>
                        <span class="account_number">账号</span>
                        <el-input
                            style="width: 250px; border: 0"
                            v-model="form.name"
                            placeholder="请输入账号"
                            class="top_two"
                        ></el-input>
                        <span class="modify_account_number" @click="modify">修改</span>
                    </el-form-item>
                </el-form>
                <!-- <div class="real_name">
                    <img src="../../assets/icon_shiming@3x.png" alt="" class="real_name_img" />
                    <div class="real_name_one">实名认证</div>
                    <div class="real_name_con" v-if="notCertified">
                        <div class="real_name_two">当前未实名认证</div>
                        <div class="real_name_three" @click="deCertification">去认证</div>
                    </div>
                    <div class="real_name_twos" v-else-if="certificationAudit">资料审核中</div>
                    <div class="real_name_con" v-else-if="institutionFailure">
                        <div class="real_name_two_cons">机构认证失败</div>
                        <div class="real_name_three" @click="organizationView">重新认证</div>
                    </div>
                    <div class="real_name_con" v-else-if="personalFailure">
                        <div class="real_name_two_cons">个人认证失败</div>
                        <div class="real_name_three" @click="personalView">重新认证</div>
                    </div>
                    <div class="real_name_con" v-else-if="personalSuccess">
                        <div class="real_name_two_con">个人认证成功</div>
                        <div class="real_name_three_con" @click="personalView">查看</div>
                    </div>
                    <div class="real_name_con" v-else-if="institutionalSuccess">
                        <div class="real_name_two_con">机构认证成功</div>
                        <div class="real_name_three_con" @click="organizationView">查看</div>
                    </div>
                </div> -->
                <div class="submit_modification" @click="preservation">提交修改</div>
                <!-- <div class="btntop">
                    <el-button type="primary" @click="all('/enterprise')">机构认证</el-button>
                    <el-button class="personal" @click="all('/personal')">个人认证</el-button>
                </div> -->
            </div>
        </div>
        <div class="main_con" v-else-if="personalData === 2">
            <div class="title">个人实名认证</div>
            <personal-infos @change-types="onChangeTypes"></personal-infos>
        </div>
        <div class="main_con" v-else>
            <div class="title">机构实名认证</div>
            <mechanism-info @change-type="onChangeType"></mechanism-info>
        </div>
        <el-dialog center :visible.sync="authentications" width="610px">
            <img
                src="https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-09-06-18-50-00gdUKUcWt.png"
                alt=""
                class="authentication_img"
            />
            <div class="authentication_tip">当前为普通用户，请进行用户认证！</div>
            <div class="authentication_btn">
                <div class="authentication_btn_one" @click="personalCertificationButton">个人认证</div>
                <div class="authentication_btn_two" @click="(personalData = 3), (authentications = false)">
                    机构认证
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import MechanismInfo from '../../components/MechanismInfo.vue';
import PersonalInfos from '../../components/PersonalInfos.vue';
import SingleUpload from '../../components/SingleUpload.vue';
export default {
    components: { MechanismInfo, PersonalInfos, SingleUpload },
    computed: {
        ...mapState(['userInfo', 'institutionInfo', 'personalInfo', 'loginType']),
        conType() {
            return this.userInfo.authorities.find(item => {
                return item.name == 'ROLE_INSTITUTION' || item.name == 'ROLE_PERSONAL';
            });
        },
        type() {
            return this.userInfo.authorities.find(item => {
                return item.name == 'ROLE_INSTITUTION';
            });
        },
        types() {
            return this.userInfo.authorities.find(item => {
                return item.name == 'ROLE_PERSONAL';
            });
        },
        // 未认证
        notCertified() {
            return !this.agencyCertification && !this.personalCertification;
        },
        // 认证审核
        certificationAudit() {
            if (this.agencyCertification) {
                return this.agencyCertification.status === 'PENDING';
            } else if (this.personalCertification) {
                return this.personalCertification.status === 'PENDING';
            } else {
                return '';
            }
        },
        // 机构认证失败
        institutionFailure() {
            if (this.agencyCertification) {
                return this.agencyCertification.status === 'DENY';
            } else {
                return '';
            }
        },
        // 个人认证失败
        personalFailure() {
            if (this.personalCertification) {
                return this.personalCertification.status === 'DENY';
            } else {
                return '';
            }
        },
        // 个人认证成功
        personalSuccess() {
            if (this.personalCertification) {
                return this.personalCertification.status === 'PASS';
            } else {
                return '';
            }
        },
        // 机构认证成功
        institutionalSuccess() {
            if (this.agencyCertification) {
                return this.agencyCertification.status == 'PASS';
            } else {
                return '';
            }
        }
    },
    mounted() {
        this.form.name = this.userInfo.nickname;
        if (this.userInfo.avatar) {
            this.sizeForm.avatar = this.userInfo.avatar;
        }
        if (this.$route.query.personalData) {
            this.personalData = this.$route.query.personalData;
        }
        this.$http
            .post(
                '/personal/all',
                { query: { userId: this.userInfo.id } },
                {
                    body: 'json'
                }
            )
            .then(res => {
                this.personalCertification = res.content[0];
            })
            .catch(e => {
                console.log(e);
            });
        this.$http
            .post(
                '/institution/all',
                { query: { userId: this.userInfo.id } },
                {
                    body: 'json'
                }
            )
            .then(res => {
                this.agencyCertification = res.content[0];
            })
            .catch(e => {
                console.log(e);
            });
    },
    watch: {
        // notCertified(value) {
        //     if (value) {
        //         this.authentications = true;
        //     }
        // }
    },
    data() {
        return {
            personalCertification: {},
            agencyCertification: {},
            sizeForm: {
                avatar: 'https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-09-06-17-04-39jfwMIPtC.png'
            },
            form: {
                name: ''
            },
            authentications: false,
            personalData: 1
        };
    },
    methods: {
        onChangeType(value) {
            this.personalData = value;
        },
        onChangeTypes(value) {
            this.personalData = value;
        },
        uploadFileFn() {
            document.getElementById('uploadFile').click();
        },
        upload(e) {
            this.sizeForm.avatar = window.URL.createObjectURL(e.target.files[0]);
            console.log('15e4e4ws', this.sizeForm.avatar);
        },
        modify() {
            this.form.name = '';
        },
        deCertification() {
            this.authentications = true;
        },
        personalCertificationButton() {
            this.authentications = false;
            this.personalData = 2;
        },
        preservation() {
            let userInfo = {
                nickname: this.form.name,
                avatar: this.sizeForm.avatar
            };
            this.$http
                .post('user/saveInfo', userInfo)
                .then(res => {
                    this.$message.warning('修改成功');
                    this.sizeForm.avatar = res.avatar;
                    this.$store.dispatch('getUserInfo');
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        personalView() {
            this.personalData = 2;
        },
        organizationView() {
            this.personalData = 3;
        }
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    margin-bottom: 40px;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
    .top {
        margin-left: 300px;
        margin-top: 30px;
        .img {
            /deep/ .el-upload{
                width: 148px;
                height: 148px;
                display: block;
            }
            /deep/ .upload-image {
                width: 148px !important;
                height: 148px !important;
                border-radius: 50% !important;
                margin-left: 57px;
                border: none;
            }
        }
        p {
            margin: 40px 0;
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            line-height: 28px;
        }
        .btntop {
            margin-left: 32px;
            .personal {
                background: rgba(520, 226, 200, 0.7);
                color: #ff8700;
            }
        }
    }
}
.el-icon-user {
    font-size: 20px;
    margin-right: 6px;
    color: #000000;
}
/deep/ .el-dialog {
    margin-top: 25vh !important;
}
.authentication_img {
    width: 98px;
    height: 98px;
    margin-bottom: 20px;
    margin-left: 256px;
}
.authentication_tip {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    line-height: 28px;
    margin-bottom: 40px;
    margin-left: 161px;
}
.authentication_btn {
    display: flex;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    margin-left: 185px;
    cursor: pointer;
    .authentication_btn_one {
        width: 110px;
        height: 36px;
        background: rgba(1, 160, 65, 0.1);
        border-radius: 4px;
        border: 1px solid #01a041;
        margin-right: 20px;
        color: #01a041;
        line-height: 36px;
    }
    .authentication_btn_two {
        width: 110px;
        height: 36px;
        background: #01a041;
        border-radius: 4px;
        color: #ffffff;
        line-height: 36px;
    }
}
.top_one {
    border: none;
    padding-left: 60px;
}
.top_two {
    border: none !important;
    border-bottom: 1px solid @bg;
    padding-left: 19px;
    /deep/.el-input__inner {
        border-width: 0;
    }
}
.real_name_img {
    width: 22px !important;
    height: 22px !important;
    margin-left: 0px !important;
    margin-right: 6px;
}
.real_name_con {
    display: flex;
}
.form_con{
    border-bottom: 1px solid @bg;
}
.real_name {
    margin-top: 20px;
    margin-left: -60px;
    width: 400px;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @bg;
    .real_name_one {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        margin-right: 30px;
    }
    .real_name_two {
        font-size: 16px;
        font-weight: 400;
        color: #939599;
        line-height: 24px;
        margin-right: 123px;
    }
    .real_name_two_con {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 150px;
        color: #000000;
    }
    .real_name_two_cons {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 123px;
        color: red;
    }
    .real_name_twos {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 123px;
        color: #ff8700;
    }
    .real_name_three {
        cursor: pointer;
        font-size: 13px;
        font-weight: 400;
        color: #01a041;
        line-height: 24px;
    }
    .real_name_three_con {
        cursor: pointer;
        font-size: 13px;
        font-weight: 400;
        color: #939599;
        line-height: 24px;
    }
}
.modify_account_number {
    font-size: 13px;
    font-weight: 400;
    color: #01a041;
    line-height: 24px;
    cursor: pointer;
    margin-left: 10px;
}
.account_number {
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    margin-right: 30px;
}
.submit_modification {
    cursor: pointer;
    margin-top: 90px;
    margin-left: 15px;
    width: 221px;
    height: 36px;
    background: #01a041;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
}
</style>
